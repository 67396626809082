var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('div',[_c('b-modal',{attrs:{"id":"delete_modal","title":"Confirm","size":"sm","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":""}},[_c('div',{staticClass:"text-center"},[_c('h1',[_vm._v("Are you sure?")]),_c('p',[_vm._v(" You are about to "+_vm._s(_vm.delete_participant_item.banned ? "unban" : "ban")+" the user:"),_c('br'),_c('strong',[_vm._v("\""+_vm._s(_vm.delete_participant_item.first_name)+" "+_vm._s(_vm.delete_participant_item.last_name)+"\"")]),_vm._v(" from the hackathon. ")]),_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"danger","pill":true},on:{"click":function($event){return _vm.ban_unban_participant(
                                !_vm.delete_participant_item.banned
                            )}}},[_vm._v(" "+_vm._s(_vm.delete_participant_item.banned ? "Unban" : "Ban")+" ")]),_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"primary","pill":true},on:{"click":function($event){return _vm.$bvModal.hide('delete_modal')}}},[_vm._v("Cancel ")])],1)])],1),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h1',[_vm._v("Hackathon Participants")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('el-tooltip',{attrs:{"content":"Copy link for hackathon.","placement":"top"}},[_c('i',{staticClass:"fas fa-share-alt text-primary",on:{"click":function($event){_vm.copy_to_clipboard(
                                        _vm.get_hackathon_share_url()
                                    )}}})])],1)],1),(_vm.$apollo.loading && _vm.participants.length == 0)?_c('div',[_c('b-row',{staticClass:"mt-2 d-flex align-items-center justify-content-center"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center"},[_c('Transition',[_c('h1',[_c('i',{staticClass:"fas fa-spinner fa-spin ml-1"})])])],1)],1)],1):_c('b-row',[_c('b-col',[_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"row-class-name":"table-rows","data":_vm.participants}},[_c('el-table-column',{attrs:{"label":"Name","min-width":"100px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.first_name)+"  "+_vm._s(row.last_name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"","max-width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [(row.linkedin_url)?_c('a',{attrs:{"href":("" + (row.linkedin_url)),"target":"_blank"}},[_c('i',{staticClass:"fab fa-linkedin-in"})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Email","min-width":"100px","prop":"email","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_c('a',{staticStyle:{},attrs:{"href":("mailto:" + (row.email))}},[_vm._v(_vm._s(row.email))])]}}])}),_c('el-table-column',{attrs:{"label":"Banned","min-width":"100px","prop":"banned","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.banned ? "Yes" : "No")+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"80px","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":"Ban/Unban Participant","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Ban Participant"},on:{"click":function($event){$event.preventDefault();return _vm.show_delete_modal(row)}}},[_c('i',{staticClass:"fas fa-trash"})])])],1)}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }