<template>
    <b-row>
        <b-col>
            <!-- Modals -->
            <div>
                <!-- Delete Modal -->
                <b-modal
                    id="delete_modal"
                    title="Confirm"
                    size="sm"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-footer
                >
                    <div class="text-center">
                        <h1>Are you sure?</h1>
                        <p>
                            You are about to
                            {{
                                delete_participant_item.banned ? "unban" : "ban"
                            }}
                            the user:<br />
                            <strong
                                >"{{
                                    delete_participant_item.first_name
                                }}&nbsp;{{
                                    delete_participant_item.last_name
                                }}"</strong
                            >
                            from the hackathon.
                        </p>
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="
                                ban_unban_participant(
                                    !delete_participant_item.banned
                                )
                            "
                        >
                            {{
                                delete_participant_item.banned ? "Unban" : "Ban"
                            }}
                        </base-button>
                        <base-button
                            type="primary"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('delete_modal')"
                            >Cancel
                        </base-button>
                    </div>
                </b-modal>
                <!-- CRU Modal -->
                <!-- <b-modal
                    id="cru_modal"
                    :title="
                        cru_modal.mode == 'create'
                            ? 'Create program event'
                            : 'Edit program event'
                    "
                    size="md"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-footer
                >
                    <div>
                        <b-row>
                            <b-col>
                                <validation-observer
                                    v-slot="{ handleSubmit }"
                                    ref="formValidator"
                                >
                                    <b-form
                                        role="form"
                                        @submit.prevent="
                                            handleSubmit(submit_form)
                                        "
                                    >
                                        <input
                                            type="submit"
                                            ref="form_submit_button"
                                            hidden
                                        />
                                        <b-row>
                                            <b-col>
                                                <base-input
                                                    :pill="true"
                                                    label="Name*"
                                                    class="mb-3"
                                                    name="Name"
                                                    :rules="{ required: true }"
                                                    placeholder="Name of the program event"
                                                    v-model="
                                                        cru_modal.form.name
                                                    "
                                                    :disabled="
                                                        cru_modal.loading
                                                    "
                                                >
                                                </base-input>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                ><html-editor
                                                    :pill="true"
                                                    label="Program event detail description"
                                                    class="mb-3"
                                                    name="Detail Description"
                                                    v-model="
                                                        cru_modal.form
                                                            .description
                                                    "
                                                    :disabled="
                                                        cru_modal.loading
                                                    "
                                                >
                                                </html-editor
                                            ></b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="12" md="6">
                                                <base-input
                                                    :pill="true"
                                                    type="datetime-local"
                                                    id="start-datetime"
                                                    label="Start date and time"
                                                    v-model="
                                                        cru_modal.form
                                                            .start_time
                                                    "
                                                    :rules="{ required: true }"
                                                    :disabled="
                                                        cru_modal.loading
                                                    "
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="12" md="6">
                                                <base-input
                                                    :pill="true"
                                                    type="datetime-local"
                                                    id="end-datetime"
                                                    label="End date and time"
                                                    v-model="
                                                        cru_modal.form.end_time
                                                    "
                                                    :rules="{ required: true }"
                                                    :disabled="
                                                        cru_modal.loading
                                                    "
                                                ></base-input>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </validation-observer>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col class="text-right">
                                <base-button
                                    type="danger"
                                    :pill="true"
                                    class="my-4 btn-success"
                                    @click="$bvModal.hide('cru_modal')"
                                    :disabled="cru_modal.loading"
                                    >Cancel
                                </base-button>
                                <base-button
                                    type="success"
                                    :pill="true"
                                    class="my-4 btn-success"
                                    @click="save_cru_modal()"
                                    :disabled="cru_modal.loading"
                                    :loading="cru_modal.loading"
                                    :success="cru_modal.success"
                                    >Save
                                </base-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-modal> -->
            </div>
            <b-card no-body>
                <b-card-body>
                    <b-row>
                        <b-col cols="6">
                            <h1>Hackathon Participants</h1>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <!-- <el-tooltip content="Add" placement="top">
                                <base-button
                                    type="primary"
                                    round
                                    icon
                                    size="sm"
                                    @click="$bvModal.show('c_modal')"
                                >
                                    <span class="btn-inner"
                                        ><i class="fa fa-plus"></i
                                    ></span>
                                </base-button>
                            </el-tooltip> -->
                            <el-tooltip
                                content="Copy link for hackathon."
                                placement="top"
                            >
                                <i
                                    @click="
                                        copy_to_clipboard(
                                            get_hackathon_share_url()
                                        )
                                    "
                                    class="fas fa-share-alt text-primary"
                                ></i>
                            </el-tooltip>
                        </b-col>
                    </b-row>

                    <div v-if="$apollo.loading && participants.length == 0">
                        <b-row
                            class="mt-2 d-flex align-items-center justify-content-center"
                        >
                            <b-col
                                class="d-flex align-items-center justify-content-center"
                            >
                                <Transition>
                                    <h1>
                                        <i
                                            class="fas fa-spinner fa-spin ml-1"
                                        ></i>
                                    </h1>
                                </Transition>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row v-else>
                        <b-col>
                            <el-table
                                class="table-responsive align-items-center table-flush"
                                row-class-name="table-rows"
                                :data="participants"
                            >
                                <el-table-column
                                    label="Name"
                                    min-width="100px"
                                    prop="name"
                                    sortable
                                >
                                    <template v-slot="{ row }">
                                        {{ row.first_name }} &nbsp;{{
                                            row.last_name
                                        }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="" max-width="50px">
                                    <template v-slot="{ row }">
                                        <a
                                            v-if="row.linkedin_url"
                                            :href="`${row.linkedin_url}`"
                                            target="_blank"
                                            ><i class="fab fa-linkedin-in"></i
                                        ></a>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    label="Email"
                                    min-width="100px"
                                    prop="email"
                                    sortable
                                >
                                    <template v-slot="{ row }">
                                        <a style="text-nowrap" :href="`mailto:${row.email}`">{{
                                            row.email
                                        }}</a>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    label="Banned"
                                    min-width="100px"
                                    prop="banned"
                                    sortable
                                >
                                    <template v-slot="{ row }">
                                        {{ row.banned ? "Yes" : "No" }}
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    min-width="80px"
                                    align="right"
                                >
                                    <div
                                        slot-scope="{ row }"
                                        class="table-actions"
                                    >
                                        <!-- <el-tooltip
                                            content="Edit"
                                            placement="top"
                                        >
                                            <a
                                                href="#!"
                                                @click.prevent="
                                                    show_cru_modal(row)
                                                "
                                                class="table-action"
                                                data-toggle="tooltip"
                                                data-original-title="Edit product"
                                            >
                                                <i class="fas fa-edit"></i>
                                            </a>
                                        </el-tooltip> -->
                                        <el-tooltip
                                            content="Ban/Unban Participant"
                                            placement="top"
                                        >
                                            <a
                                                href="#!"
                                                @click.prevent="
                                                    show_delete_modal(row)
                                                "
                                                class="table-action table-action-delete"
                                                data-toggle="tooltip"
                                                data-original-title="Ban Participant"
                                            >
                                                <i class="fas fa-trash"></i>
                                            </a>
                                        </el-tooltip>
                                    </div>
                                </el-table-column>
                            </el-table>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
// Components
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
} from "element-ui";

// Queries
import { GET_HACKATHON_PARTICIPANTS } from "@/graphql/queries";
// Mutations
import { UPDATE_HACKATHON_PARTICIPANT } from "@/graphql/mutations";

export default {
    name: "HakcathonParticipants",
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    },
    props: {
        hackathon_id: {
            type: String,
            description: "The id of the Hackathon",
            default: null,
        },
    },

    apollo: {
        get_hackathon_participants: {
            query: GET_HACKATHON_PARTICIPANTS,
            result(res) {
                this.participants = [];

                let new_participants = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(
                        res.data.allHackathonParticipantParticipant
                    )
                );

                new_participants.forEach((el) => {
                    this.participants.push({
                        id: el.id,
                        first_name: el.hackathonProfile__user__firstName,
                        last_name: el.hackathonProfile__user__lastName,
                        email: el.hackathonProfile__user__email,
                        banned: el.banned,
                        linkedin_url: el.hackathonProfile__linkedinUrl,
                    });
                });
                this.hackathon_uid = res.data.hackathonHackathon.uid;
            },
            update(data) {
                this.apollo_data.get_hackathon_program = data;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {},
            participants: [],
            delete_participant_item: {},
            hackathon_uid: null,
        };
    },
    methods: {
        // Apollo
        manage_apollo_get_hackathon_participant() {
            if (this.hackathon_id != null) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_participants
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_participants
                );
            }
        },

        // Delete Modal

        show_delete_modal(delete_participant) {
            this.delete_participant_item = delete_participant;
            this.$bvModal.show("delete_modal");
        },

        // Ban/Unban a participant.
        ban_unban_participant(banned) {
            this.$apollo
                .mutate({
                    mutation: UPDATE_HACKATHON_PARTICIPANT,
                    variables: {
                        participant_id: this.delete_participant_item.id,
                        banned: banned,
                    },
                })
                .then((res) => {
                    this.$bvModal.hide("delete_modal");
                })
                .catch((res) => {
                    // TODO: Create notification that something went wrong.
                });
        },

        async copy_to_clipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                this.$notify({
                    message: `Copied to clipboard`,
                    timeout: 1000,
                    icon: "ni ni-check-bold",
                    type: "success",
                });
            } catch ($e) {
                console.log($e);
            }
        },
        get_hackathon_share_url() {
            return (
                window.location.origin +
                "/#/pub/hackathon/" +
                encodeURIComponent(this.hackathon_id) +
                "/detail?token=" +
                this.hackathon_uid +
                "&particpant_invite=true"
            );
        },
    },
    mounted() {
        this.manage_apollo_get_hackathon_participant();
    },
};
</script>

<style></style>
